import {
  type PropsWithChildren,
  type FC,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
  useCallback
} from 'react';
import { NoteModal } from '../../Components/NoteModal/NoteModal';
import { noop } from '../utils/noop';

interface INoteLogContext {
  modalIsOpen: boolean;
  saveAndAddNote: (
    func: (...args: unknown[]) => void,
    params: Array<unknown>,
    thisArg: unknown
  ) => void;
  closeModal: () => void;
  saveFunc: (newParams: INoteParams, ...args: unknown[]) => void;
}

export interface INoteParams {
  note: string;
}

export const NoteLogContext = createContext<INoteLogContext | null>(null);

export const NoteLogProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [saveFunc, setSaveFunc] = useState<INoteLogContext['saveFunc']>(noop);
  const [modalIsOpen, setModalOpen] = useState(false);

  const saveAndAddNote: INoteLogContext['saveAndAddNote'] = useCallback(
    (func, params = [], thisArg = null) => {
      setSaveFunc(
        () => (newParams: INoteParams) =>
          func.call(thisArg, newParams, ...params)
      );

      setModalOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setModalOpen(() => false);
    setSaveFunc(noop);
  }, []);

  const value = useMemo(() => {
    return {
      saveFunc,
      modalIsOpen,
      closeModal,
      saveAndAddNote
    };
  }, [closeModal, modalIsOpen, saveAndAddNote, saveFunc]);

  return (
    <NoteLogContext.Provider value={value}>
      {children}
      <NoteModal />
    </NoteLogContext.Provider>
  );
});

export const useNoteLogContext = () => {
  const value = useContext(NoteLogContext);

  if (value === null) {
    throw new Error('Wrap Component in NoteLogContext');
  }

  return value;
};
