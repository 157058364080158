import {
  type ChangeEvent,
  type FC,
  useCallback,
  useMemo,
  useState
} from 'react';
import { debounce } from 'lodash';
import { TextareaAutosize, Box } from '@mui/material';
import { useNoteLogContext } from '../../Utilities/context/NoteLogContext';
import Dialog from '../Dialog';

export const NoteModal: FC = () => {
  const { modalIsOpen, saveFunc, closeModal } = useNoteLogContext();
  const [noteText, setNoteText] = useState('');

  const onSave = useCallback(() => {
    saveFunc({ note: noteText });

    setNoteText('');

    closeModal();
  }, [closeModal, noteText, saveFunc]);

  const changeNoteText = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
        return setNoteText(e.target.value);
      }, 300),
    []
  );

  if (!modalIsOpen) {
    return null;
  }

  return (
    <Dialog
      title="Add a note"
      open={modalIsOpen}
      onRequestSave={onSave}
      onRequestClose={closeModal}
      titleStyles={{
        height: '90px'
      }}
      footerStyles={{
        justifyContent: 'space-between',
        padding: '0.5rem 1.3rem 2rem'
      }}
      buttonStyles={{
        margin: 0
      }}
    >
      <Box sx={{ padding: '0' }}>
        <TextareaAutosize
          minRows={3}
          color="neutral"
          placeholder="Please enter your note here..."
          onChange={changeNoteText}
          style={{
            width: '94%',
            minHeight: '40px',
            resize: 'none',
            padding: '10px'
          }}
        />
      </Box>
    </Dialog>
  );
};
