/* eslint-disable react/no-children-prop */
/* eslint-disable react/static-property-placement */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import FormCreator from './FormCreator';
import { SubmitButton } from './FormComponents';
import Dialog from '../Dialog/Dialog';

const styles = () => ({
  paper: {
    '@media (max-height: 455px)': {
      '& > div:first-child': {
        paddingTop: '10px'
      },
      '& > div:last-child > div:first-child > div:first-child': {
        marginBottom: '10px'
      }
    },
    '@media (max-height: 365px)': {
      '& > div:last-child > div:first-child': {
        padding: '10px'
      }
    },
    '@media (max-height: 321px)': {
      overflow: 'scroll'
    }
  }
});

class FormContainer extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    separateBlock: PropTypes.bool,
    dialog: PropTypes.object,
    formTitle: PropTypes.string,
    hideSubmit: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    testId: PropTypes.string
  };

  static defaultProps = {
    inputWidth: 10,
    separateBlock: true,
    formTitle: '',
    hideSubmit: false,
    multiline: false
  };

  handleFormSubmit = () => {
    const { formValues } = this.props;
    let firstErrorField = null;

    // Check for any errors in the form fields
    Object.keys(formValues).forEach((fieldKey) => {
      const field = formValues[fieldKey];
      if (field.error) {
        if (!firstErrorField) {
          // Capture the first error field
          firstErrorField = document.getElementById(fieldKey);
        }
      }
    });

    // If there's an error, scroll to the first error field
    if (firstErrorField) {
      firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      // No errors, proceed with form submission
      this.props.handleSubmit();
    }
  };

  renderFormTitle = () =>
    this.props.formTitle && <div>{this.props.formTitle}</div>;

  renderFormContent = () => <FormCreator {...this.props} />;

  renderFormSubmit = () => {
    if (!this.props.handleSubmit) {
      return null;
    }

    const submitBtn = (
      <SubmitButton {...this.props} handleSubmit={this.handleFormSubmit} />
    );

    if (!this.props.buttonBlockStyles) {
      return submitBtn;
    }

    return <Box sx={this.props.buttonBlockStyles}>{submitBtn}</Box>;
  };

  renderFormDialog = () =>
    this.props.dialog && (
      <Dialog
        title={this.props.dialog.title || 'Error'}
        children={this.props.dialog.message}
        classes={this.props.classes}
        open={!!this.props.dialog.message}
        onRequestClose={() =>
          setTimeout(() => {
            this.props?.dialogOnClose?.();

            this.props.toggleDialog(
              '',
              this.props.dialog.type,
              this.props.dialog.title
            );
          }, 300)
        }
        type={this.props.dialog.type}
        toggleActionDrawer={this.props.toggleActionDrawer}
      />
    );

  render() {
    return (
      <Grid container justifyContent="center" alignItems="center">
        {this.renderFormTitle()}
        {this.renderFormContent()}
        {this.renderFormSubmit()}
        {this.renderFormDialog()}
      </Grid>
    );
  }
}

export default withStyles(FormContainer, styles);
