import {
  type PropsWithChildren,
  type FC,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
  useCallback
} from 'react';
import { NoteModal } from '../../Components/NoteModal/NoteModal';

type OptionsType = Array<{ display: string; value: string }>;

interface IFilterOptionsContext {
  options: {
    [key: string]: OptionsType;
  };
  changeOptions: (name: string, newOptions: OptionsType) => void;
}

export const FilterOptionsContext = createContext<IFilterOptionsContext | null>(
  null
);

export const FilterOptionsProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const [options, setOptions] = useState<{
      [key: string]: OptionsType;
    }>({});

    const changeOptions = useCallback(
      (name: string, newOptions: OptionsType) => {
        setOptions((prevState) => {
          return {
            ...prevState,
            [name]: newOptions
          };
        });
      },
      []
    );

    const value = useMemo(() => {
      return {
        options,
        changeOptions
      };
    }, [changeOptions, options]);

    return (
      <FilterOptionsContext.Provider value={value}>
        {children}
        <NoteModal />
      </FilterOptionsContext.Provider>
    );
  }
);

export const useFilterOptionsContext = () => {
  const value = useContext(FilterOptionsContext);

  if (value === null) {
    throw new Error('Wrap Component in NoteLogContext');
  }

  return value;
};
