import React from 'react';
import { checkAccounting } from '../../PermissionsWrappers/permissionChecks';
import BuyerReport from '../../Buyers/Components/BuyerReport/BuyerReport';
import AffiliateReporting from '../../Reporting/Components/AffiliateReporting/AffiliateReporting';
import { filters as buyerReportFilters } from '../../Buyers/Components/BuyerReport/filters/filters';
import { filters as AffiliateReportingFilters } from '../../Reporting/Components/AffiliateReporting/filters/filters';
import MonthlyTotals from '../../Reporting/Components/MonthlyTotals/MonthlyTotals';
import { filters as MonthlyTotalsFilters } from '../../Reporting/Components/MonthlyTotals/filters/filters';
import SiteTotals from '../../Reporting/Components/SiteTotals/SiteTotals';
import AffiliateDetails from '../../Reporting/Components/AffiliateDetails/AffiliateDetails';
import { filters as AffiliateDetailsFilters } from '../../Reporting/Components/AffiliateDetails/filters/filters';
import AddAdvertiserRevenue from '../../Sites/Components/AddAdvertiserRevenue/render/AddAdvertiserRevenue';
import ExternalDataReport from '../../Reporting/Components/ExternalDataReport/ExternalDataReport';
import { filters as ExternalDataReportFilters } from '../../Reporting/Components/ExternalDataReport/filters/filters';
import ClientMonthlyTotals from '../../Reporting/Components/ClientMonthlyTotals/ClientMonthlyTotals';
import { filters as ClientMonthlyTotalsFilters } from '../../Reporting/Components/ClientMonthlyTotals/filters/filters';
import BuyerSummaryReport from '../../Buyers/Components/BuyerSummary/BuyerSummary';
import { filters as BuyerSummaryFilters } from '../../Buyers/Components/BuyerSummary/filters/filters';
import { type IRoute } from '../../../Utilities/types';
import TYWallProviders from '../../Sites/Components/TYWallProviders/TYWallProviders';
import { filters as TYWallProvidersFilters } from '../../Sites/Components/TYWallProviders/filters';

const accountingList = {
  site_totals: {
    section: 'Reports',
    displayName: 'Sites',
    content: <SiteTotals />,
    displayDateRange: true,
    newRanges: true
  },
  affiliate_reporting: {
    section: 'Reports',
    displayName: 'Affiliates',
    content: <AffiliateReporting />,
    displayDateRange: true,
    newRanges: true,
    filters: AffiliateReportingFilters()
  },
  affiliate_details: {
    section: 'Reports',
    displayName: 'SubID Report',
    content: <AffiliateDetails />,
    displayDateRange: true,
    newRanges: true,
    filters: AffiliateDetailsFilters()
  },
  buyer_report: {
    section: 'Reports',
    displayName: 'Buyers',
    content: <BuyerReport />,
    displayDateRange: true,
    newRanges: true,
    filters: buyerReportFilters()
  },
  monthly_report: {
    section: 'Reports',
    displayName: 'Monthly Totals',
    content: <MonthlyTotals />,
    displayDateRange: false,
    filters: MonthlyTotalsFilters()
  },
  client_monthly_report: {
    section: 'Reports',
    displayName: 'Client Monthly Totals',
    content: <ClientMonthlyTotals />,
    displayDateRange: false,
    filters: ClientMonthlyTotalsFilters()
  },
  add_advertiser_revenue: {
    section: 'Reports',
    displayName: 'Add Invoiced Revenue',
    content: <AddAdvertiserRevenue />
  },
  external_data_report: {
    displayName: 'External Data',
    content: <ExternalDataReport />,
    displayDateRange: true,
    newRanges: true,
    section: 'Reports',
    filters: ExternalDataReportFilters()
  },
  buyer_summary_report: {
    section: 'Reports',
    displayName: 'Buyer Summary',
    content: <BuyerSummaryReport />,
    filters: BuyerSummaryFilters()
  },
  ty_wall_providers: {
    section: 'Reports',
    displayName: 'TY Wall Carriers',
    content: <TYWallProviders />,
    displayDateRange: true,
    newRanges: true,
    filters: TYWallProvidersFilters()
  },
  lb_wall_providers: {
    section: 'Reports',
    displayName: 'LB Wall Carriers',
    content: <TYWallProviders LB />,
    displayDateRange: true,
    newRanges: true,
    filters: TYWallProvidersFilters()
  }
};

export const AccountReports = (): Record<string, IRoute> => {
  if (checkAccounting()) {
    return accountingList;
  }

  return {};
};

export const sections = ['Reports'];
