const CANCELED_ERROR_NAME = 'CanceledError';

const isObj = (error: unknown): error is Record<string, unknown> => {
  return !!(error && typeof error === 'object');
};

export const isCanceledError = (error: unknown) => {
  return !!(
    isObj(error) &&
    'name' in error &&
    error.name === CANCELED_ERROR_NAME
  );
};

/* eslint-disable no-console */
export const logError = (error: unknown) => {
  if (!isCanceledError(error)) {
    console.error(error);
  }
};
