import { affiliateID, siteOptions } from '../../../common/commonRequests';

const s1Options = [
  { value: '0', display: 'Form Numbers' },
  { value: '1', display: 'S1 Numbers' }
];

export const filters = () => ({
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => siteOptions(),
    value: 2,
    inputWidth: 3,
    width: 6
  },
  affiliateID: {
    name: 'affiliateID',
    type: 'autocomplete',
    label: 'Affiliate',
    options: () => affiliateID(),
    value: 'all',
    inputWidth: 3,
    width: 6
  },
  phoneS1State: {
    name: 'phoneS1State',
    type: 'select',
    label: 'Number Type',
    options: () => s1Options,
    value: '0',
    inputWidth: 3,
    width: 6
  }
});
