/* eslint-disable no-param-reassign */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  TextField,
  Select,
  FileUpload,
  AutoComplete,
  MultiSelect,
  Checkbox,
  Scale
} from './FormComponents';
import ImageUpload from './FormComponents/ImageUpload';
import PickStatesMap from './FormComponents/PickStatesMap';
import MultiInputs from './FormComponents/MultiInputs';
import Toggle from './FormComponents/Toggle';
import MultiCheckbox from './FormComponents/MultiCheckbox';

const fieldMapping = {
  input: (props) => <TextField key={props.name} {...props} />,
  select: (props) => <Select key={props.name} {...props} />,
  checkbox: (props) => <Checkbox key={props.name} {...props} />,
  multicheckbox: (props) => <MultiCheckbox key={props.name} {...props} />,
  csv: (props) => <FileUpload key={props.name} {...props} />,
  autocomplete: (props) => <AutoComplete key={props.name} {...props} />,
  multiselect: (props) => <MultiSelect key={props.name} {...props} />,
  image: (props) => <ImageUpload key={props.name} {...props} />,
  pickStatesMap: (props) => <PickStatesMap key={props.name} {...props} />,
  toggle: (props) => <Toggle key={props.name} {...props} />,
  scale: (props) => <Scale key={props.name} {...props} />,
  custom: ({ Component, ...props }) => <Component {...props} />,
  multiinputs: (props) => <MultiInputs key={props.name} {...props} />
};

export default class FormCreator extends Component {
  static propTypes = {
    formValues: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    separateBlock: PropTypes.bool
  };

  formatFormValues = (formValues) => {
    formValues = { ...this.props.formValues[formValues.name] };
    formValues.onChange = this.props.handleChange;
    formValues.hideFilter = this.props.hideFilter;
    formValues.rows = this.props.rows;
    return formValues;
  };

  renderInputField = (formValues) => {
    return fieldMapping[formValues.type](this.formatFormValues(formValues));
  };

  renderInputItem = (formKey, formValues) =>
    this.renderInputField(formValues[formKey]);

  renderSeparateBlockInput = (formKey, formValues, inputWidth) => (
    <Grid key={formKey} container alignItems="center" justifyContent="center">
      <div style={{ fontWeight: 700 }}>{formValues[formKey]?.section}</div>
      {this.renderInputItem(formKey, formValues, inputWidth)}
    </Grid>
  );

  renderCaption = (caption, captionStyle) => (
    <Grid container alignItems="center" justifyContent="center">
      <Typography variant="caption" style={{ margin: '15px', ...captionStyle }}>
        {caption}
      </Typography>
    </Grid>
  );

  render() {
    const {
      formValues,
      inputWidth,
      separateBlock,
      mobileInputWidth,
      hiddenFilterList
    } = this.props;
    const keys = Object.keys(formValues);
    return (
      <Grid
        id="formContainer"
        container
        justifyContent="center"
        alignItems="center"
      >
        {keys.map(
          (formKey) =>
            !formValues[formKey].hidden &&
            !hiddenFilterList?.includes(formKey) && (
              <Grid
                id={formKey}
                key={formKey}
                item
                sx={
                  this.props.itemStyles ?? {
                    width: formValues[formKey].itemWidth ?? '66.67%'
                  }
                }
                xs={
                  formValues[formKey].width
                    ? formValues[formKey].width
                    : mobileInputWidth
                }
                md={
                  formValues[formKey].width
                    ? formValues[formKey].width
                    : inputWidth
                }
              >
                {separateBlock
                  ? this.renderSeparateBlockInput(
                      formKey,
                      formValues,
                      inputWidth
                    )
                  : this.renderInputItem(formKey, formValues, inputWidth)}
                {formValues[formKey].caption &&
                  this.renderCaption(
                    formValues[formKey].caption,
                    formValues[formKey].captionStyle || {}
                  )}
              </Grid>
            )
        )}
      </Grid>
    );
  }
}

// Below is an example of some formValues objects
//
// {
//   text_input: {
//     name: 'text_input',
//     type: 'input',
//     label: 'Text Input',
//     inputType: 'text',
//     validate: () => {},
//     value: '',
//     error: false,
//     message: '',
//   },
//   number_input: {
//     name: 'number_input',
//     min: 0.03,
//     max: 3.00,
//     type: 'input',
//     label: 'Number Input',
//     inputType: ' number',
//     inputProps: {step: .01, min: 0.01, max: 0.50},
//     validate: () => {},
//     adornment: '$',
//     adornmentPosition: 'start',
//     value: 0.05,
//     error: false,
//     message: '',
//   },
//   select: {
//     name: 'select',
//     type: 'select',
//     label: 'Select ',
//     validate: () => {},
//     options: [{value: '', display: ''}],
//     value: '',
//     error: false,
//     message: '',
//   },
// }
