/* eslint-disable react/no-access-state-in-setstate */
import { AccessTime, List } from '@mui/icons-material';
import { Button } from '@mui/material';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logError } from '../../../../Utilities/logError';
import BuyerAffiliatesBreakdown from '../../../Buyers/Components/BuyerReport/BuyerAffiliatesBreakdown';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import ActionDrawer from '../../../PageContainer/ActionDrawer';
import {
  updateBreadCrumbs,
  handleChange as updatePage
} from '../../../PageContainer/actions';
import Table from '../../../TableContainer/Table';
import { renderReportLink } from '../../../TableContainer/TableUtilities/RenderReportLink';
import { getTableData } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  getColumnCompare,
  numberWithCommas
} from '../../../TableContainer/TableUtilities/columnFormatter';
import {
  renderCurrencyCell,
  renderDefaultCell,
  renderNumberCell,
  renderPercentCell
} from '../../../TableContainer/TableUtilities/defaultCells';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';
import { saveToLocalStorage } from '../../../Utilities/saveStateHelper';
import { withRouter } from '../../../Utilities/withRouter';
import { isMobileDevice } from '../../../common/isMobileDevice';
import { getTYRequests } from '../../requests';
import { ROUTE_NAMES } from '../../../NavBar/NavigationContainer';

const columns = [
  { name: 'bids', title: 'Bids', compare: 'priority' },
  { name: 'avg_bids', title: 'Bid Avg.', compare: 'priority' },
  { name: 'wins', title: 'Wins', compare: 'priority' },
  { name: 'wins_percent', title: 'Wins %', compare: 'priority' },
  { name: 'win_bid', title: 'Winning Bid Avg.', compare: 'priority' },
  { name: 'clicks', title: 'Clicks', compare: 'priority' },
  { name: 'revenue', title: 'Revenue', compare: 'priority' },
  { name: 'epc', title: 'EPC', compare: 'priority' }
];

const breakdownColumns = [
  { name: 'affiliate', title: 'Affiliate', width: 300 },
  ...columns
];

const totalMapping = {
  provider: { type: 'header' },
  buyer: { type: 'header' },
  bids: { type: 'number' },
  avg_bids: {
    type: 'average_currency',
    dividend: (rows) =>
      rows.reduce((total, row) => {
        if (!row) {
          return 0;
        }

        const valueForAverage = row.bids * row.avg_bids;
        return total + parseFloat(valueForAverage);
      }, 0),
    divisor: (rows) =>
      rows.reduce((total, row) => {
        if (!row) {
          return 0;
        }
        return total + parseFloat(row.bids);
      }, 0)
  },
  wins: { type: 'number' },
  wins_percent: { type: 'percent', dividend: 'wins', divisor: 'bids' },
  win_bid: {
    type: 'average_currency',
    dividend: (rows) =>
      rows.reduce((total, row) => {
        if (!row) {
          return 0;
        }
        const valueForAverage = row.wins * row.win_bid;
        return total + parseFloat(valueForAverage);
      }, 0),
    divisor: (rows) =>
      rows.reduce((total, row) => {
        if (!row) {
          return 0;
        }
        return total + parseFloat(row.wins);
      }, 0)
  },
  clicks: { type: 'number' },
  revenue: { type: 'currency' },
  epc: { type: 'average_currency', dividend: 'revenue', divisor: 'clicks' },
  breakdown: false
};

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  date_range: props.dateRange,
  site_id: props.site,
  affiliate_id: props.affiliateID,
  source: props.LB ? 'LB' : 'TY'
});

class TYWallProviders extends Component {
  state = {
    rows: [],
    loading: true,
    providersBuyersWay: false,
    detailedTable: false,
    loadingError: false
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        buildTableParams('getTyWallCarriers', this.props),
        buildTableParams('getTyWallCarriers', prevProps)
      )
    ) {
      this.getData();
    }
  }

  setBreadCrumb = (buyer, affiliate) => {
    const { breadCrumbs, LB } = this.props;
    saveToLocalStorage(`hourlyBuyerTotalsbuyer`, buyer);
    saveToLocalStorage(`hourlyBuyerTotalsaffiliateID`, affiliate);
    this.props.updateFilters('buyer', buyer);
    this.props.updateFilters('affiliateID', affiliate);
    this.props.updateBreadCrumbs(
      breadCrumbs,
      'Reporting',
      LB ? 'lb_wall_providers' : 'ty_wall_providers',
      LB ? 'LB Wall Providers' : 'TY Wall Providers',
      'hourlyBuyerTotals'
    );
  };

  getData = () =>
    this.setState({ loading: true, loadingError: false }, () =>
      getTableData(
        buildTableParams('getTyWallCarriers', this.props),
        getTYRequests,
        (values) => {
          this.setState({
            ...values,
            rows: values.rows
              ? values.rows[
                  this.state.providersBuyersWay ? 'providers' : 'buyers'
                ]
              : [],
            data: values.rows,
            detailedTable: false
          });
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      })
    );

  getColumns = () => {
    const { providersBuyersWay, detailedTable } = this.state;
    if (
      (providersBuyersWay && !detailedTable) ||
      (!providersBuyersWay && detailedTable)
    ) {
      return [
        {
          name: 'provider',
          title: 'Provider',
          width: isMobileDevice() ? 250 : 300
        },
        ...columns
      ];
    }
    return [
      { name: 'buyer', title: 'Buyer', width: isMobileDevice() ? 250 : 300 },
      { name: 'breakdown', title: 'Breakdown', width: 170 },
      ...columns
    ];
  };

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'buyer':
      case 'provider':
        return !this.state.detailedTable
          ? renderReportLink(
              row[column.name],
              row[column.name],
              (value) => value,
              () => {},
              () => this.switchToDetailedData(row[column.name], column.name),
              null,
              `Click to see ${
                column.name === 'provider'
                  ? 'buyers for this carrier'
                  : 'carriers for this buyer'
              }`
            )
          : renderDefaultCell(row[column.name]);
      case 'affiliate':
        return renderReportLink(
          `${row.affiliate_name} (#${row.affiliate_id})`,
          row.buyer,
          (value) => value,
          (buyer) =>
            this.setBreadCrumb(
              buyer,
              `${row.affiliate_name} (#${row.affiliate_id})`
            ),
          () => {
            this.props.updatePage('selectedPage', 'hourlyBuyerTotals');
            this.props.router.navigate(
              `/${ROUTE_NAMES.Reporting}/hourlyBuyerTotals`
            );
          },
          AccessTime
        );
      case 'bids':
      case 'wins':
        return renderNumberCell(row[column.name]);
      case 'avg_bids':
      case 'win_bid':
      case 'revenue':
      case 'epc':
        return renderCurrencyCell(row[column.name]);
      case 'wins_percent':
        return renderPercentCell(row[column.name]);
      case 'clicks':
        return renderDefaultCell(numberWithCommas(row[column.name]));
      case 'breakdown':
        return renderDefaultCell(this.renderBreakdownButton(row));
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  switchToDetailedData = (item, column) => {
    this.setState({ loading: true, loadingError: false }, () => {
      const params = buildTableParams('getTyWallCarriers', this.props);
      params[column] = item;
      getTableData(params, getTYRequests, (values) => {
        this.setState({
          rows: values.rows,
          loading: false,
          detailedTable: true
        });
      }).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  showWholeData = () =>
    this.setState(({ data, providersBuyersWay }) => ({
      rows: data[providersBuyersWay ? 'providers' : 'buyers'],
      detailedTable: false
    }));

  changeMainTable = (providersBuyersWay) =>
    this.setState(
      ({ data }) => ({
        rows: data[providersBuyersWay ? 'providers' : 'buyers'],
        providersBuyersWay,
        detailedTable: false,
        filtersState: []
      }),
      () => this.setState({ filtersState: undefined })
    );

  renderBreakdownButton = (row) => (
    <div>
      <ActionDrawer
        fullWidth
        drawerTitle="Affiliates Breakdown"
        customIcon={<List />}
        tooltip="Affiliate Breakdown"
        content={
          <BuyerAffiliatesBreakdown
            params={buildTableParams('getTyWallCarriersByBuyer', this.props)}
            buyer={row.buyer}
            columns={breakdownColumns}
            cellComponent={this.cellComponent}
            endpoint="ty"
            sortingColumn="revenue"
          />
        }
      />
    </div>
  );

  getTableColumnStorageName = () => {
    const { providersBuyersWay } = this.state;
    const { LB } = this.props;
    if (LB) {
      if (providersBuyersWay) {
        return 'lb_wall_providers_carriers_buyers';
      }
      return 'lb_wall_providers_buyers_carriers';
    }
    if (providersBuyersWay) {
      return 'ty_wall_providers_carriers_buyers';
    }
    return 'ty_wall_providers_buyers_carriers';
  };

  render() {
    const {
      rows,
      loading,
      detailedTable,
      providersBuyersWay,
      filtersState,
      loadingError
    } = this.state;
    const modColumns = this.getColumns();
    const hasData = !!this.state.data;

    return (
      <div>
        <Button
          variant="contained"
          onClick={() => this.changeMainTable(!providersBuyersWay)}
          disabled={!hasData}
          style={
            isMobileDevice()
              ? {
                  display: 'block',
                  margin: '1rem auto'
                }
              : {}
          }
        >
          Change to {providersBuyersWay ? 'Buyers-Carriers' : 'Carriers-Buyers'}
        </Button>
        {detailedTable && (
          <Button variant="contained" onClick={this.showWholeData}>
            Back To {providersBuyersWay ? 'Carriers' : 'Buyers'}
          </Button>
        )}
        <Table
          loading={loading}
          rows={rows}
          columns={modColumns}
          cellComponent={this.cellComponent}
          defaultColumnWidths={getColumnWidths(modColumns)}
          getColumnCompare={getColumnCompare(modColumns)}
          drawerTitle={this.props.LB ? 'LB Wall Carriers' : 'TY Wall Carriers'}
          tableColumnLocalStorageName={this.getTableColumnStorageName()}
          defaultSortingOrder={[{ columnName: 'revenue', direction: 'desc' }]}
          height="auto"
          totalsMapping={totalMapping}
          filtersState={filtersState}
          loadingError={loadingError}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    peak: state.dateRange.peak,
    site: state.filters.site,
    affiliateID: formatAffiliateValue(state.filters.affiliateID),
    breadCrumbs: state.pageReducer.breadCrumbs
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updatePage,
        updateBreadCrumbs,
        updateFilters
      },
      dispatch
    )
)(withRouter(TYWallProviders));
