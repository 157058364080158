/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { TableCell } from '@mui/material';
import { Table as TableContainer, TableRemotePag } from './index';
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../Utilities/saveStateHelper';

export default class Table extends Component {
  state = {
    pageSize: getFromLocalStorage('rowsPerPage') || 50
  };

  static defaultProps = {
    cellComponent: ({ row, column }) => (
      <TableCell style={{ paddingLeft: 10 }}>{row[column.name]}</TableCell>
    ),
    defaultColumnWidths: [],
    tableColumnLocalStorageName: 'table',
    remotePagination: false,
    loading: false,
    rows: [],
    columns: [],
    additionalCellProps: {},
    defaultSortingOrder: []
  };

  onPageSizeChange = (newSize) => {
    this.setState({ pageSize: newSize });
    this.props.onPageSizeChange?.(newSize);

    saveToLocalStorage('rowsPerPage', newSize);
  };

  tableContent = () => {
    return this.props.remotePagination ? (
      <TableRemotePag
        allowedPageSizes={[10, 25, 50, 100]}
        rows={this.props.rows}
        rowComponent={this.props.rowComponent}
        columns={this.props.columns}
        loading={this.props.loading}
        cellComponent={this.props.cellComponent}
        defaultColumnWidths={this.props.defaultColumnWidths}
        getColumnCompare={this.props.getColumnCompare}
        tableColumnLocalStorageName={this.props.tableColumnLocalStorageName}
        defaultSortingOrder={this.props.defaultSortingOrder}
        pageSize={this.props.pageSize ?? this.state.pageSize}
        changePageSize={this.onPageSizeChange}
        {...this.props}
      />
    ) : (
      <TableContainer
        allowedPageSizes={[10, 25, 50, 100]}
        rows={this.props.rows}
        columns={this.props.columns}
        loading={this.props.loading}
        rowComponent={this.props.rowComponent}
        cellComponent={this.props.cellComponent}
        defaultColumnWidths={this.props.defaultColumnWidths}
        getColumnCompare={this.props.getColumnCompare}
        tableColumnLocalStorageName={this.props.tableColumnLocalStorageName}
        defaultSortingOrder={this.props.defaultSortingOrder}
        mobileHeight={this.props.rows.length > 5 ? 500 : 250}
        defaultPageSize={this.props.pageSize || this.pageSize}
        changePageSize={this.onPageSizeChange}
        additionalHeader={this.props.additionalHeader}
        pageSize={this.props.pageSize ?? this.state.pageSize}
        {...this.props}
      />
    );
  };

  render() {
    return this.tableContent();
  }
}
