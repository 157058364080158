import { getSellers } from '../../../common/commonRequests';
import { clickPurchasesSiteOptions } from '../ClickPurchases/filters/filters';
import { type LogFiltersType } from './consts';

type LogOptionsFiltersType = Array<{
  value: LogFiltersType;
  display: string;
}>;

const logOptionsFilters: LogOptionsFiltersType = [
  { value: 'general', display: 'General Settings' },
  { value: 'presets', display: 'Applying Presets' },
  { value: 'schedule', display: 'Bid Mod Schedules' },
  { value: 'source_bid_caps', display: 'Source Bid Caps' },
  { value: 'fixed_bid', display: 'Fixed Bid' },
  { value: 'options', display: 'Options' },
  { value: 'states', display: 'States' }
];

export const filters = () => ({
  logOption: {
    name: 'logOption',
    type: 'multiselect',
    label: 'Options',
    value: [logOptionsFilters[0].value],
    options: () => logOptionsFilters
  },
  site: {
    name: 'site',
    type: 'select',
    label: 'Vertical',
    options: () => clickPurchasesSiteOptions,
    value: '0'
  },
  logsSeller: {
    name: 'logsSeller',
    type: 'select',
    label: 'Seller',
    saveOptions: true,
    options: (dependency?: string | undefined) => getSellers(dependency, false),
    inputWidth: 3,
    value: '-1',
    checkifValueExists: true,
    dependencyName: 'site'
  }
});
